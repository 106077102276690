import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';
import confetti from 'canvas-confetti';
import { MediaService } from 'src/app/services/media.service';
import { environment } from 'src/environments/environment';
import { nanoid } from 'nanoid';
@Component({
  selector: 'app-quiz-component',
  templateUrl: './quiz-component.component.html',
  styleUrls: ['./quiz-component.component.scss'],
})
export class QuizComponentComponent implements OnInit {
  constructor(
    private playerSessionService: PlayerSessionService,
    private playerService: PlayerService,
    public mediaService: MediaService
  ) {}
  @Input() component: any;
  completed = false;
  environment = environment;

  qNum = 1;
  score = 0;
  showAnswerBtn: any = false;
  answersSelected: any = [];
  currQuestion: any;

  answered: any = [];
  correct: any = [];
  questionStyle: any = [];
  uuid = nanoid();
  autoAdvanceTimeout: any;

  @Output() activityEnded: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (!this.playerSessionService.sessionData.taskResponses) {
      this.playerSessionService.sessionData.taskResponses = {};
    }
    this.playerSessionService.sessionData.taskResponses[this.component.id] = [];

    this.component.data.questions.forEach((question: any) => {
      question.answers.forEach((ans: any, index: any) => {
        ans.selectedState = null;
      });
    });

    if (this.component.style.questionColor) {
      this.questionStyle['color'] = this.component.style.questionColor;
    }
    if (this.component.style.questionScale) {
      this.questionStyle['font-size'] =
        this.component.style.questionScale + '%';
      this.questionStyle['line-height'] = '1';
    }
    if (this.component.style.questionAlign) {
      this.questionStyle['text-align'] = this.component.style.questionAlign;
    }
    if (this.component.style.questionPadding) {
      let padding = '0';
      if (String(this.component.style.questionPadding).indexOf(' ') >= 0) {
        padding =
          this.component.style.questionPadding.replaceAll(' ', 'px ') + 'px';
      } else {
        padding = this.component.style.questionPadding + 'px';
      }
      this.questionStyle['padding'] = padding;
    }
    if (!this.component.style?.answerRadius) {
      this.component.style.answerRadius = 5;
    }
  }

  getQuestionStyle() {
    let questionStyle: any = [];
    if (this.component.style.questionColor) {
      questionStyle['color'] = this.component.style.questionColor;
    }
    if (this.component.style.questionScale) {
      questionStyle['font-size'] = this.component.style.questionScale + '%';
      questionStyle['line-height'] = '1';
    }
    if (this.component.style.questionAlign) {
      questionStyle['text-align'] = this.component.style.questionAlign;
    }
    if (this.component.style.questionPadding) {
      let padding = '0';
      if (String(this.component.style.questionPadding).indexOf(' ') >= 0) {
        padding =
          this.component.style.questionPadding.replaceAll(' ', 'px ') + 'px';
      } else {
        padding = this.component.style.questionPadding + 'px';
      }
      questionStyle['padding'] = padding;
    }
    return questionStyle;
  }

  getAnswerStyle() {
    let answerStyle: any = [];
    if (this.component.style.answerScale) {
      answerStyle['font-size'] = this.component.style.answerScale + '%';
      answerStyle['line-height'] = '1';
    }
    if (this.component.style.answerRadius) {
      answerStyle['--answer-border-radius'] =
        this.component.style.answerRadius + 'px';
    }
    return answerStyle;
  }

  onAnswerQuiz(question: any, answers: any, manualAnswer: any) {

    if(this.answered.indexOf(question) == -1){
    var qKey = this.qNum - 1;
    this.currQuestion = question;
    // are there multiple corrects?
    var totCorrect = 0;

    question.answers.forEach((ans: any, index: any) => {
      if (ans.correct == 1) {
        totCorrect++;
      }
    });

    // multiple answers
    if (totCorrect > 1 && manualAnswer == false) {
      if (answers[0].selectedState == 'selected') {
        answers[0].selectedState = null;
        // unselect it
        this.answersSelected.forEach((_a: any, _i: any) => {
          if (_a.answer == answers[0].answer) {
            this.answersSelected.splice(_i, 1);
          }
        });

        if (this.answersSelected.length == 0) {
          this.showAnswerBtn = false;
        }
      } else {
        this.showAnswerBtn = true;
        //
        this.answersSelected.push(answers[0]);

        // what have they selected?
        answers[0].selectedState = 'selected';
      }
    } else {
      this.answersSelected = answers;
      if (this.answered.indexOf(question) == -1) {
        question.correct = true;
        question.answers.forEach((a: any, index: any) => {
          // loop through answrs and assign value
          var selected = false;
          answers.forEach((sa: any, saIndex: any) => {
            if (sa.answer == a.answer) {
              selected = true;
              if (sa.correct) {
                question.answers[index].selectedState = 'selectedCorrect';
              } else {
                question.answers[index].selectedState = 'selectedIncorrect';
                question.correct = false;
              }
            }
          });

          if (selected == false) {
            if (a.correct) {
              question.answers[index].selectedState = 'unselectedCorrect';
              question.correct = false;
            } else {
              question.answers[index].selectedState = 'unselectedIncorrect';
            }
          }
        });

        this.answered.push(question);
        // question.correct = question.correct;
        if (question.correct == true) {
          if (this.component.data.correctSound) {
            this.playerService.playAudio(
              this.environment.mediaUrl +
                '/assets/' +
                this.component.data.correctSound,
              'step'
            );
          }
          this.score++;
          this.correct.push(question);
        } else {
          if (this.component.data.incorrectSound) {
            this.playerService.playAudio(
              this.environment.mediaUrl +
                '/assets/' +
                this.component.data.incorrectSound,
              'step'
            );
          }
        }
      }

      this.playerSessionService.addToLog('quizAnswered', {
        id: this.component.id,
        question: question.question,
        answers: this.answersSelected,
        score: this.score,
      });
      this.showAnswerBtn = false;
      this.answersSelected = [];
      this.currQuestion;

      if (this.component.data.actions) {
        this.component.data.actions.forEach((action: any) => {
          this.playerService.doAction(
            this.component,
            action,
            null,
            'onQuizQuestionAnswered',
            { question, answers }
          );
        });
      }

      if (this.component.data.autoAdvance) {
        this.autoAdvanceTimeout = setTimeout(() => {
          this.onNextQuestion();
        }, 3000);
      }
    }
  }
  }

  onNextQuestion() {
    clearTimeout(this.autoAdvanceTimeout);
    let quizQa = document.getElementById(
      'quiz-qa-' + this.uuid + '-' + this.component.id
    );
    quizQa?.classList.remove(
      'animate__animated',
      'animate__delay-1s',
      'animate__repeat-1',
      'animate__' + this.component.questionAnimationIn?.name
    );
    quizQa?.classList.add(
      'animate__animated',
      'animate__delay-1s',
      'animate__repeat-1',
      'animate__' + this.component.questionAnimationOut?.name
    );

    setTimeout(() => {
      quizQa = document.getElementById(
        'quiz-qa-' + this.uuid + '-' + this.component.id
      );
      this.qNum++;
      this.showAnswerBtn = false;
      this.answersSelected = [];
      this.currQuestion;
      quizQa?.classList.remove(
        'animate__animated',
        'animate__repeat-1',
        'animate__' + this.component.questionAnimationOut?.name
      );
      quizQa?.classList.add(
        'animate__animated',
        'animate__repeat-1',
        'animate__' + this.component.questionAnimationIn?.name
      );

      if (this.qNum > this.component.data.questions.length) {
        //this.activityEnded.emit(this.component);
        // do actions here
        this.playerSessionService.sessionData.taskResponses[
          this.component.id
        ].push({
          quizData: {
            score: this.score,
          },
        });
        // do it
        if (this.component.data.actions) {
          this.component.data.actions.forEach((action: any) => {
            this.playerService.doAction(
              this.component,
              action,
              null,
              'onQuizResults',
              this.score
            );
          });
        }
      }
    }, this.component.questionAnimationOut?.speed * 500);
  }

  onComplete() {
    this.playerSessionService.addToLog('quizCompleted', {
      id: this.component.id,
      score: this.score,
    });

    this.completed = true;
    this.activityEnded.emit(this.component);
    this.playerService.executeActionQueue();
  }

  onRetake() {
    this.qNum = 1;
    this.score = 0;
    this.answered = [];
    this.correct = [];
    this.answersSelected = [];
    this.component.data.questions.forEach((question: any) => {
      question.answers.forEach((ans: any, index: any) => {
        ans.selectedState = null;
      });
    });
  }
}
